



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import json2xls from 'json2xls';
import VuePapaParse from 'vue-papa-parse';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Global, User } from '@/store';

Vue.use(VuePapaParse);

@Component({})
export default class InventoryExportData extends Vue {
  @Prop({ default: () => [] }) devices;
  @Prop() filter_fields;

  @User.State('project') project;

  formats = ['csv', 'xlsx', 'json'];
  exportedData = [];
  formatted_filter_fields = {};

  mounted(){
    Object.entries(this.filter_fields)
      .map(([field_name, value]) => [PropUtils.getFieldLowerToUpper(field_name), value])
      .forEach(([field_name, value], index) => {
        this.formatted_filter_fields[`Filter ${index + 1}: ${field_name}`] = value;
      }); 
  }

  async exportData(format) {
    this.exportedData = [];
    await this.createExportedData();

    const filename = this.project.name + ' - ' + Utils.getDateString();
    let data;

    switch (format) {
      case 'json':
        data = this.project.name ? JSON.stringify([{source : this.project.name}, ...this.exportedData], null, '\t') : JSON.stringify(this.exportedData, null, '\t');
        break;

      case 'csv':
        // @ts-ignore
        data = this.$papa.unparse(this.exportedData);
        break;

      case 'xlsx':
        data = json2xls(this.exportedData);
        break;
    }

    this.download(filename, format, data);
  }

  download(filename, format, data) {
    if (data) {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
      const link = document.createElement('a');
      let url = null;
      if (format === 'xlsx') {
        url = 'data:application/octet-stream;charset=utf-16le;base64,' + btoa(data);
      }
      else {
        url = URL.createObjectURL(blob);
      }
      link.setAttribute('href', url);
      link.setAttribute('download', filename + '.' + format);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async createExportedData() {
    await Promise.all(this.devices.map(async (device) => {
      try {
        const general_data = {
          'ID': device.id,
          'Name': device.name,
          'Cabinet ID': device['meta.device'].cabinet_id,
          'Pole Number': device['meta.device'].pole_number,
          'Address': Utils.deviceLocation(device)
        };

        Object.entries(this.filter_fields).forEach(([field_name, value]) => {
          const uppercase_field = PropUtils.getFieldLowerToUpper(field_name);
          if (!general_data.hasOwnProperty(uppercase_field)) general_data[uppercase_field] = device['meta.device'][field_name];
        });

        const final_data = {...general_data, ...this.formatted_filter_fields};

        this.exportedData.push(final_data);
      }
      catch (err) {
        console.log('error:', err);
      }
    }));
    this.exportedData.sort((device1, device2) => device1['Cabinet ID'] > device2['Cabinet ID'] ? 1 : device1['Cabinet ID'] < device2['Cabinet ID'] ? -1 : 0);
  }
}
